<template>
    <ToDoRow 
    :show-documentbuttons="showDocumentbuttons"
    :blocked="blocked"
    :todo="todo" :disable-check="disableCheck"
    @checked:value="this.onChecked" @save-document="saveDocument"
    @pressed:value="modalOpen = true, this.handleClick(todo.name, todo.name == 'Llamada' ? 'CALL' : 'APPOINTMENT', todo.id);"
    :id="todo.id" />
    <ModalBasic 
        :size="'max-w-7xl'"
        id="confirmation-modal" :modalOpen="confirmationModalOpen" @close-modal="confirmationModalOpen = false" title="Confirmacion">

    </ModalBasic> 
    <ModalBasic 
        :size="'w-full md:w-2/3'"
        id="feedback-modal" :modalOpen="modalOpen" @close-modal="modalOpen = false" title="Calendario">
        <button
          class="btn px-2.5 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300 disabled:border-slate-200 dark:disabled:border-slate-700 disabled:bg-slate-100 dark:disabled:bg-slate-800 disabled:text-slate-400 dark:disabled:text-slate-600 disabled:cursor-not-allowed"
          :disabled="month === 0 ? true : false"
          @click="previous"
        >
          <span class="sr-only">Previous month</span><wbr />
          <svg class="h-4 w-4 fill-current" viewBox="0 0 16 16">
            <path d="M9.4 13.4l1.4-1.4-4-4 4-4-1.4-1.4L4 8z" />
          </svg>
        </button>

        <!-- Next month button -->
        <button
          class="btn px-2.5 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300 disabled:border-slate-200 dark:disabled:border-slate-700 disabled:bg-slate-100 dark:disabled:bg-slate-800 disabled:text-slate-400 dark:disabled:text-slate-600 disabled:cursor-not-allowed"
          :disabled="month === 11 ? true : false"
          @click="next"
        >
          <span class="sr-only">Next month</span><wbr />
          <svg class="h-4 w-4 fill-current" viewBox="0 0 16 16">
            <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
          </svg>
        </button>
        <CalendarComponent
            :users-options="this.users"
            @save:event="saveEvent"
            :key="componentKey" :allow-edit="false" :is-loading="this.isLoadingEdit"
            :block-inputs="true" :name-new-event="this.nameNewEvent" :client-id-new-event="this.prospectId"
            :type-new-event="this.typeNewEvent"
            :small="true"
            :view-mode="this.viewMode"
            :current-day-start-date="this.currentDayStartDate"
            :starting-blank-days="this.startingBlankDays"
            :ending-blank-days="this.endingBlankDays"
            :days-in-month="this.daysInMonth"
            :filtered-events="this.events"
            :month="this.month"
            :year="this.year"
            :today="this.today"
            />
      </ModalBasic>
</template>
<script>
    import { getDays } from '@/utils/getDays.js';
    import { editData } from '@/utils/editData.js';
    import { fetchData } from '@/utils/fetchData.js';
    import { toUTCString } from '@/utils/toUTCString.js';

    import Swal from 'sweetalert2';
    import ToDoRow from '@/components/ToDoRow.vue';
    import api from '@/interceptors/axiosInterceptos'; 
    import ModalBasic from '@/components/ModalBasic.vue';
    import CalendarComponent from '@/partials/calendar/CalendarComponent.vue';

    export default {
        name: 'ToDoAppointmentRow',
        components: {
            ToDoRow,
            ModalBasic,
            CalendarComponent
        },
        emits: ['checked:value', 'save', 'saveDocument'],
        props:{
            blocked: Boolean,
            todo: Object,
            prospectId: String,
            events_: Array,
            showDocumentbuttons: { type: Boolean, default: function(){ return false } },
            disableCheck: {
                type: Boolean,
                default: function(){
                    return false;
                }
            },
        },
        data(){
            console.log(this.events_)
            const today = new Date();
            return {
                modalOpen: false,
                confirmationModalOpen: false,
                isLoadingEdit: false,
                componentKey: 0,
                todoId: null,
                nameNewEvent: null,
                typeNewEvent: null,
                today,
                viewMode: 'week',
                currentWeekStartDate: today,
                currentDayStartDate: today,
                month: today.getMonth(),
                year: today.getFullYear(),
                daysInMonth: [],
                startingBlankDays: [],
                endingBlankDays: [],
                users: [],
                events: this.events_
            }
        },
        methods: {
            saveDocument(object){
                this.$emit('save-document', object);
            },
            onChecked(todo){
                var eventDate;
                if (todo?.event?.eventDate !== null && todo?.event?.eventDate !== undefined){
                    const date = new Date(todo.event.eventDate);
                    eventDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}T${date.toLocaleTimeString([], { hour12: false, hour: 'numeric', minute: 'numeric' })}`
                } else {
                    eventDate = ''
                }

                Swal.fire({
                    title: "Finalizar TO DO",
                    text: "Confirma la fecha",
                    input: 'datetime-local',
                    confirmButtonText: 'Confirmar',
                    cancelButtonText: 'Cancelar',
                    showCancelButton: true,
                    inputValue: eventDate,
                    didOpen: () => {
                        const date = new Date();
                        const todoDate = new Date(todo.createdAt);
                        const eventMax = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}T${date.toLocaleTimeString([], { hour12: false, hour: 'numeric', minute: 'numeric' })}`
                        const eventMin = `${todoDate.getFullYear()}-${String(todoDate.getMonth() + 1).padStart(2, '0')}-${String(todoDate.getDate()).padStart(2, '0')}T${todoDate.toLocaleTimeString([], { hour12: false, hour: 'numeric', minute: 'numeric' })}`
                        Swal.getInput().max = eventMax;
                        Swal.getInput().min = eventMin;
                    },
                    inputValidator: (value) => {
                        const today = new Date();
                        const date = new Date(value);
                        const todoDate = new Date(todo.createdAt);
                        if (!value) {
                            return "Es necesario rellenar la fecha de realizacion del TO DO!";
                        }
                        if (date > today){
                            return "La fecha de finalizacion no puede ser mayor a la fecha actual!";
                        }
                        if (date < todoDate){
                            return "La fecha de finalizacion no puede ser menor a la fecha de creacion de la tarea!";
                        }
                    }
                }).then(async (result) => {
                    if (result.isConfirmed ) {
                    var formData = {};
                    if(todo.name == 'Cita'){
                        formData = {appointmentStatus: 'DONE'}
                    } else {
                        formData = {callStatus: 'DONE'}
                    }
                    if(todo.event === null || todo.event === undefined){
                        const body = {
                            productId: null,
                            clientId: this.$route.params.id,
                            name: todo.name,
                            notes: null,
                            status: "DONE",
                            type: todo.name == 'Cita' ? 'APPOINTMENT' : 'CALL',
                            eventDate: `${result.value}`,
                            eventEndDate: null,
                            recurrencePattern: null,
                            recurrenceInterval: null,
                            recurrenceEndDate: null,
                            selectedUsers: []
                        }
                        const response = await this.postEvent(body)
                        if(!response){
                            return
                        }
                        await editData(`todos/${todo.id}`, {eventId: response.data.id, status: 'DONE'}, 'El Evento y TO DO se han guardado con exito.', 'clientTodos', 'clientTodos', this, false);
                        this.componentKey += 1;
                        this.$emit('save', {formData, clientTodos: this.clientTodos});
                    }else {
                        editData(`events/${todo.event.id}`, {status: 'DONE', eventDate: `${result.value}`, clientId: this.$route.params.id, selectedUsers:[]}, '', 'events', 'events', this, false);
                        await editData(`todos/${todo.id}`, {status: 'DONE'}, 'El Evento y TO DO se han guardado con exito.', 'clientTodos', 'clientTodos', this, false);
                        var updatedTodo = todo;
                        updatedTodo.status = 'DONE';
                        this.$emit('save', {formData, clientTodos: this.clientTodos, todo: updatedTodo});
                        this.componentKey += 1;
                        
                    }
                    this.isLoadingEdit = false;
                    } else {
                    }
                    this.$emit('checked:value',null);
                });
            },
            handleClick(name, type, todoId){
                this.todoId = todoId;
                this.nameNewEvent = name
                this.typeNewEvent = type
                this.componentKey += 1;
            },
            next(){
                if(this.viewMode == 'month'){
                    this.month++;
                } else if(this.viewMode == 'week'){
                    this.currentWeekStartDate.setDate(this.currentWeekStartDate.getDate() + 7);
                    this.month = this.currentWeekStartDate.getMonth()
                    this.year = this.currentWeekStartDate.getFullYear()
                }else if(this.viewMode === 'day'){
                    this.currentDayStartDate.setDate(this.currentWeekStartDate.getDate() + 1);
                    this.month = this.currentWeekStartDate.getMonth()
                    this.year = this.currentWeekStartDate.getFullYear()
                }
                getDays(this.viewMode, this)
            },
            previous(){
                if(this.viewMode == 'month'){
                    this.month--;
                }else if(this.viewMode == 'week'){
                    this.currentWeekStartDate.setDate(this.currentWeekStartDate.getDate() - 7);
                    this.month = this.currentWeekStartDate.getMonth()
                }else if(this.viewMode === 'day'){
                    this.currentDayStartDate.setDate(this.currentWeekStartDate.getDate() - 1);
                    this.month = this.currentWeekStartDate.getMonth()
                }
                getDays(this.viewMode, this)
            },
            async fetchEvents() {
                return fetchData(`events`, 'events', 'events', this, false);
            },
            async postEvent(body){
                try {
                    const token = localStorage.getItem('token');
                    const headers = { 
                        "authorization": `Bearer ${token}`,
                    };
                    return await api.post(`${process.env.API_URL}/events/`, body, { headers });
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: error.response.data['error'] ?? error.response.data['message'],
                    })
                    return false
                }
            },
            async saveEvent(formData) {
                try {
                    this.isLoadingEdit = true;
                    const body = {
                        productId: null,
                        clientId: formData.clientId,
                        name: formData.name,
                        description: formData.description,
                        notes: null,
                        status: "TODO",
                        type: formData.type,
                        eventDate: toUTCString(formData.eventDate, formData.eventHour),
                        eventEndDate: formData.eventEndHour != null ? toUTCString(formData.eventEndDate, formData.eventEndHour) : null,
                        recurrencePattern: null,
                        recurrenceInterval: null,
                        recurrenceEndDate: null,
                        selectedUsers: []
                    }
                    const response = await this.postEvent(body)
                    if(response.status == 200){
                        await editData(`todos/${this.todoId}`, {eventId: response.data.id, status: 'INPROGRESS'}, 'El Evento y TO DO se han guardado con exito.', 'clientTodos', 'clientTodos', this);
                        if(this.nameNewEvent == 'Cita'){
                            formData = {appointmentStatus: 'SCHEDULED'}
                        } else {
                            formData = {callStatus: 'SCHEDULED'}
                        }
                        var updatedTodo = this.todo;
                        updatedTodo.event = response.data;
                        this.$emit('save', {formData, clientTodos: this.clientTodos, todo: updatedTodo});
                        this.fetchEvents();
                        this.componentKey += 1;
                    }
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: error.response.data['error'] ?? error.response.data['message'],
                    })
                }
                this.isLoadingEdit = false;
            },
        },
        created(){
            getDays(this.viewMode, this);
        }
    }
</script>