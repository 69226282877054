<template>
    <div class="grow">
      <!-- Panel body -->
      <div class="p-6 space-y-6">
        <h2 class="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">Servicios</h2>
        <span v-if="isLoading">
            <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
        </span>
        <AplicableProductsTable v-else
            @handle-service:value="handleService"
            :show-document-todos="true" :permissions="permissions"
            :show-start-button="canStartProduct()"
            :full-list="this.allProducts" :can-edit="canEdit"
            :meta-data="true"
            :key="this.componentKey"
            :title="'Productos Aplicables'"
            :title-modal="'Agregar Subcategoria de comision'"
            :is-loading_="isLoading" @update:model="addProduct" @remove:model="removeProduct"
            :list_="this.clientProducts"
            />
    </div>
  </div>  
</template>

<script>
    import { editData } from '@/utils/editData.js';
    import { postData } from '@/utils/postData.js';
    import { deleteData } from '@/utils/deleteData.js';
    import {hasSpecificPermission} from '@/utils/hasSpecificPermission'
    import { loadFromLocalStorage, setDataInStore } from '@/utils/loadFromLocalStorage.js';
    
    import Swal from 'sweetalert2';
    import api from '@/interceptors/axiosInterceptos'; 
    import AplicableProductsTable from '../create/AplicableProductsTable.vue';

    export default {
        name: 'InteractionsPanel',
        components: {  
            AplicableProductsTable
        },
        props: {
            clientTodos_: Array,
            allProducts: Array,
            prospectId: String,
            products_: Array,
            canEdit: { type: Boolean, default: function(){ return false } },
        },
        data(){
            return {
                componentKey: 0,
                clientProducts: this.products_,
                isLoading: false,
                clientTodos: this.clientTodos_,
                permissions: {
                    canStartProduct: false,
                    canGenerateContract: false,
                    canValidateContract: false,
                    canValidateDocument: false,
                    canDeleteProduct: false,
                    canRejectProduct: false,
                    canFinishProduct: false,
                }
            }
        },
        methods: {
            canStartProduct(){
                return this.clientTodos.filter(todo => todo.type == 'PROSPECT').every(element => 
                    element.status === 'DONE'
                );
            },
            async handleService(object) {
                const titles = {
                    generate: 'Generar Contratos',
                    start: "Comenzar Servicio",
                    finish: "Finalizar Servicio",
                    regenerate: 'Regenerar URL'
                };
                const texts = {
                    generate: "Confirma que deseas comenzar el proceso de firma esto creara un url que sera enviado por correo, se podra copiar tambien directo al portapapeles, ya no podra ser posible editar los documentos ni las preguntas requeridas para generacion de contrato. La expiracion es de 24 horas despues debera generarse un nuevo URL",
                    start: "Confirma que deseas comenzar el Servicio esto creara las tareas pendientes necesarias para generar la firma de contrato",
                    finish: "Confirma que deseas finalizar el Servicio esto concluira por completo el servicio",
                    regenerate: "Confirma que deseas regenerar el URL esto anulara el URL anterior por completo, cualquier progreso subido no se vera afectado, este proceso es util despues de 24 horas que es el tiempo de expiracion del URL"
                };
                const endpoints = {
                    start: 'client-products/start',
                    finish: 'client-products/finish',
                    generate: 'client-products/generate-contracts',
                    regenerate: 'signing-v2/generate-url'
                };
                const requestData = {
                    start: { productId: object.product.id, clientId: this.prospectId },
                    finish: { productId: object.product.id },
                    generate: { productId: object.product.id, clientId: this.prospectId },
                    regenerate: { productId: object.product.id, clientId: this.prospectId }
                };
                Swal.fire({
                    title: titles[object.action],
                    text: texts[object.action],
                    confirmButtonText: 'Confirmar',
                    cancelButtonText: 'Cancelar',
                    showCancelButton: true,
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const response = await postData(endpoints[object.action], requestData[object.action], this);
                        if (!response) return;
                        const index = this.clientProducts.findIndex(p => p.id === object.product.id);
                        if (index !== -1) {
                            if(object.action === "generate"){
                                var clientProduct = this.clientProducts[index]
                                clientProduct.contractExtras = response.contractExtras
                                this.clientProducts.splice(index, 1);
                                this.clientProducts.splice(index, 0, clientProduct);
                                await setDataInStore('clientProducts', this.clientProducts)
                            } else if(object.action === "start") {
                                // TODO: SET THIS 
                                // existingProspect.closingStatus = 'INPROGRESS';
                                this.clientProducts.splice(index, 1);
                                this.clientProducts.splice(index, 0, response);
                                await setDataInStore('clientProducts', this.clientProducts)
                            } else if(object.action === "finish"){
                                var clientProduct = this.clientProducts[index]
                                clientProduct.status = "DONE"
                                this.clientProducts.splice(index, 1);
                                this.clientProducts.splice(index, 0, clientProduct);
                                await setDataInStore('clientProducts', this.clientProducts)
                            } else if(object.action === "regenerate"){
                                var clientProduct = this.clientProducts[index]
                                clientProduct.contractExtras[0].todo.notes = response.documentUrl
                                this.clientProducts.splice(index, 1);
                                this.clientProducts.splice(index, 0, clientProduct);
                                await setDataInStore('clientProducts', this.clientProducts)
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Exito!',
                                    text: 'Nuevo URL generado con exito.',
                                });
                                navigator.clipboard.writeText(response.documentUrl)
                            }
                            
                        }
                    }
                });
            },
            convertToClient(product){
                // TODO: maybe delete this function and this proccess happens automatically
                Swal.fire({
                    title: "Comenzar Producto",
                    text: "Confirma que deseas comenzar el producto",
                    confirmButtonText: 'Confirmar',
                    cancelButtonText: 'Cancelar',
                    showCancelButton: true,
                }).then(async (result) => {
                    if (result.isConfirmed ) {
                        this.isLoading = true;
                        editData(`prospects/${this.$route.params.id}`, {isProspect: false}, '', 'prospects', 'prospects', this, false);
                        await editData(`client-products/${product.id}`, {status: 'INPROGRESS'},'', 'clientProducts', 'clientProducts', this, false);
                        this.$router.push(`/clients/${this.$route.params.id}`);
                        this.isLoading = false;
                    } else {
                    }
                    
                });
                // Edit statusProduct
                // Edit isProspect
                // redirect page
            },
            async addProduct(product){
                try {
                    this.isLoading = true;
                    const token = localStorage.getItem('token');
                    const headers = { 
                        "authorization": `Bearer ${token}`,
                    };
                    const body = {
                        productId: product.id,
                        clientId: this.prospectId
                    }
                    const response = await api.post(`${process.env.API_URL}/client-products/`,  body, { headers });
                    if(response.status == 200){                    
                        Swal.fire({
                            icon: 'success',
                            title: 'Exito!',
                            text: 'El producto se agrego.',
                        });
                        this.isLoading = false;
                        this.clientProducts.push(response.data);
                        await setDataInStore('clientProducts', this.clientProducts)
                    }
                    
                } catch (error) {
                    this.isLoading = false;
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: error.response?.data['error'] ?? error.response.data['message'],
                    })
                    this.isLoading = false;
                }
            },
            async removeProduct(id){
                const response = await deleteData('client-products', id, this, 'El servicio se a eliminado exitosamente.', true, true)
                if(!response){
                    return
                }
                const updated = this.clientProducts.filter((e) => e.id !== id);
                await setDataInStore('clientProducts', updated)
                this.clientProducts = this.clientProducts.filter((e) => e.id !== id)
                this.componentKey++
            },
        },
        async created(){
            hasSpecificPermission('permissions.canStartProduct', 'action', ["start_client_product"], this)
            hasSpecificPermission('permissions.canDeleteProduct', 'action', ["delete_client_product"], this)
            hasSpecificPermission('permissions.canGenerateContract', 'action', ["generate_client_contract"], this)
            hasSpecificPermission('permissions.canValidateContract', 'action', ["validate_client_contract"], this)
            hasSpecificPermission('permissions.canValidateDocument', 'action', ["validate_client_document"], this)
            hasSpecificPermission('permissions.canRejectProduct', 'action', ["reject_client_product"], this)
            hasSpecificPermission('permissions.canFinishProduct', 'action', ["finish_client_product"], this)
        }
    }
</script>