<template>
    <div class="p-4">
      <button @click="$emit('close-detail')" class="text-red-500 mb-4">Cerrar</button>
      <div class="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 p-4">
        <h2 class="font-semibold text-slate-800 dark:text-slate-100 mb-3">{{ product.name }}</h2>
        <!-- TODO: could change conditional to be based on status instead of stepExtras.length -->
        <span v-if="product.stepExtras.length == 0">

            <StartServiceButton :permissions="permissions" :product="product" :is-loading="isLoading" :start-product="startProduct" :show-start-button="showStartButton" :delete-element="deleteElement" />
            <GenerateContractButton v-if="permissions.canGenerateContract" :product="product" :is-loading="isLoading" :generate-contract="generateContract" :show-document-todos="showDocumentTodos"/>
            <DeleteButton v-if="permissions.canDeleteProduct" :clickHandler="() => handleService({todo: {}, action: 'rejectService'})" />
            <div class="space-y-4 grid grid-cols-1 gap-x-6 sm:grid-cols-6 mb-4 ">
                <ExtrasProspectionAccordion
                    :can-edit="false"
                    :is-loading="this.isLoading"
                    :products="this.list"
                    :form-data_="JSON.parse(product.productMetaData)"
                    />
            </div>
            <ExtrasAccordion v-if="showDocumentTodos && product.extras?.length > 0" class="mb-4" title="Documentos Requeridos para generacion de contrato">
                <div class="w-full md:pl-10" :key="todoComponentKey">
                    <span v-if="!isLoading">
                        <h2 class="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">TO DO's</h2>
                        <ToDoDocumentRow v-for="(element, j) in product.extras" :key="element.clientDocument?.id"
                            :disable-check="true" :disable-inputs="element.todo.status == 'DONE'"
                            :document="element.clientDocument" :permissions="permissions"
                            :todo="element.todo" 
                            @validate:value="handleService" @reject:value="handleService"
                            @save-document="handleSave($event, j)"
                            @checked:value="onChecked"
                            @pressed:value="handleRequestDuringSignature(element.clientDocument, j)"/>
                    </span>
                    <span v-else>
                        <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
                    </span>
                </div>
            </ExtrasAccordion>

            <ExtrasAccordion v-if="showDocumentTodos && product.extras?.length > 0 && product.clientSurveys.length > 0" class="mb-4" title="Preguntas Requeridas para generacion de contrato">
                <ClientSurveyForm 
                @handle-submit:value="submitForm"
                :clientSurveys_="product.clientSurveys" :disable-inputs="product.contractExtras?.length > 0"/>
            </ExtrasAccordion>

            <ExtrasAccordion v-if="showDocumentTodos && product.contractExtras?.length > 0" class="mb-4" title="Contratos en Espera de Firma">
                <span v-if="!isLoading">
                    <div class="flex">
                        <CopytoClipboardButton :content-to-copy="product.contractExtras[0].todo.notes" :button-text="'Copiar URL'" :notification-text="'URL copiado'" /> 
                        <button @click="regenerateUrl(product)"><font-awesome-icon class="mr-2" :icon="['fas', 'arrows-rotate']" />Regenerar URL</button>
                    </div>
                    <div class="mt-2">
                    <ToDoContractRow v-for="(element, j) in product.contractExtras" :key="element.clientDocument?.id"
                        :disable-check="true" :disable-inputs="!checkIfAllDone(product.extras)" :permissions="permissions"
                        :document="element.clientContract"
                        :todo="element.todo" :to-sign-document="element.toSignDocument"
                        @save-document="handleSave($event, j)" @validate:value="handleService" @reject:value="handleService"
                        @checked:value="onChecked"
                        @pressed:value="handlePressed(element, j)"/>
                    </div>
                </span>
                <span v-else>
                    <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
                </span>
            </ExtrasAccordion>
        </span> 
        <span v-else-if="this.product.status != 'REJECTED'">
            <FinishServiceButton v-if="permissions.canFinishProduct" :all-done="checkIfAllDone(product.stepExtras)" :product="product" :finish-product="finishProduct" />
            <span v-if="isLoading"><font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...</span>
            <span v-else v-for="(element) in product.stepExtras" :key="element.clientStep?.id">
                <div v-if="element.clientStep.stepMetaData.hasDocument && element.todo.document.id === null">Paso creado previo a adicion para documento, contactar a sistema porfavor para arreglarlo</div>
                <template v-else-if="element.clientStep.stepMetaData.type == 'UserAppointment'">
                    <ToDoAppointmentRow
                    :show-documentbuttons="element.clientStep.stepMetaData.hasDocument"
                    :key="todoComponentKey" :disable-check="!hasRoleId(element.clientStep.stepMetaData.roleIds)"
                    :events_="this.events"
                    :prospect-id="this.prospectId"
                    :todo="element.todo"
                    :blocked="checkIfBlocked(element, product.stepExtras)"
                    @checked:value="onCheckedTodoAppointmentRow" @save-document="saveDocumentStep($event, element.todo)"
                    @save="emitSave"/>
                </template>
                <template v-else-if="element.clientStep.stepMetaData.type == 'UserTodo'">
                    <ToDoRow 
                    :show-documentbuttons="element.clientStep.stepMetaData.hasDocument"
                    :key="todoComponentKey"
                    :show-attach-button="false" :disable-when-done="this.product.status !== 'INPROGRESS'"
                    :todo="element.todo" :disable-check="!hasRoleId(element.clientStep.stepMetaData.roleIds)"
                    :blocked="checkIfBlocked(element, product.stepExtras)"
                    @checked:value="onCheckedTodoRow" @save-document="saveDocumentStep"
                    :id="element.todo.id" />
                </template>
                <template v-else-if="element.clientStep.stepMetaData.type == 'Authorization'">
                    <ToDoRow 
                    :show-documentbuttons="element.clientStep.stepMetaData.hasDocument"
                    :can-authorize="hasRoleId(element.clientStep.stepMetaData.roleIds)"
                    :key="todoComponentKey" :authorization="true" :disable-check="true"
                    :show-attach-button="false" :disable-when-done="this.product.status !== 'INPROGRESS'"
                    :todo="element.todo" 
                    :blocked="checkIfBlocked(element, product.stepExtras)"
                    @validate:value="handleService" @reject:value="handleService"
                    @checked:value="onCheckedTodoAuthorizationRow" @save-document="saveDocumentStep"
                    :id="element.todo.id" />
                </template>
                <template v-else>
                    No widget for this type of todo
                </template>
            </span>
        </span>
      </div>
    </div>
  </template>
  
<script>
    import { editData } from '@/utils/editData.js';
    import { postData } from '@/utils/postData.js';
    import { fetchData } from '@/utils/fetchData.js';
    import {texts} from '@/utils/confirmationAlertTexts.js';
    import {titles} from '@/utils/confirmationAlertTitles.js';
    import { loadFromLocalStorage } from '@/utils/loadFromLocalStorage.js';

    import Swal from 'sweetalert2';
    import api from '@/interceptors/axiosInterceptos'; 
    import ToDoRow from '@/components/ToDoRow.vue';
    import ExtrasAccordion from '@/components/ExtrasAccordion.vue';
    import ToDoDocumentRow from '@/components/ToDoDocumentRow.vue';
    import ToDoContractRow from '@/components/ToDoContractRow.vue';
    import DeleteButton from '@/components/buttons/DeleteButton.vue'
    import ToDoAppointmentRow from '@/components/ToDoAppointmentRow.vue';
    import CopytoClipboardButton from '@/components/buttons/CopytoClipboardButton.vue'
    import ClientSurveyForm from '@/partials/prospects/detail/services/ClientSurveyForm.vue'
    import StartServiceButton from '@/partials/prospects/detail/services/StartServiceButton.vue'
    import FinishServiceButton from '@/partials/prospects/detail/services/FinishServiceButton.vue'
    import GenerateContractButton from '@/partials/prospects/detail/services/GenerateContractButton.vue'
    import ExtrasProspectionAccordion from '@/partials/prospects/create/ExtrasProspectionAccordion.vue';
  export default {
    name: 'ProductDetail',
    props: {
        permissions: Object,
        list: Array,
        startProduct: Function,
        finishProduct: Function,
        generateContract: Function,
        regenerateUrl: Function,
        handleRequestDuringSignature: Function,
        onChecked: Function,
        handleSave: Function,
        product: null,
        showDocumentTodos: { type: Boolean, default: function(){ return false } },
        showStartButton: { type: Boolean, default: function(){ return false } },
    },
    components: {
        ToDoRow,
        DeleteButton,
        ToDoDocumentRow,
        ToDoContractRow,
        ExtrasAccordion,
        ClientSurveyForm,
        StartServiceButton,
        ToDoAppointmentRow,
        FinishServiceButton,
        CopytoClipboardButton,
        GenerateContractButton,
        ExtrasProspectionAccordion
    },
    data(){
        return {
            roles: [],
            user: null,
            prospectId: null,
            todoComponentKey: 0,
            isLoading: false,
            events: [],
            clientTodos: [],
            clientDocuments: [],
            clientSurveys: [],
        }
    },
    methods: {
        async saveDocumentStep(event, todo){
            this.isLoading = true;
            try {
                const token = localStorage.getItem('token');
                const headers = { 
                    "authorization": `Bearer ${token}`,
                };
                const response = await api.put(`${process.env.API_URL}/client-documents/${event.documentId}`, event.body, { headers });
                if(response.status == 200){
                    Swal.fire({
                        icon: 'success',
                        title: 'Exito!',
                        text: 'El documento se a editado exitosamente.',
                    });
                    this.todoComponentKey += 1;
            
                    const index = this.product.stepExtras.findIndex((element) => element.todo.id === todo.id);
                    if (index !== -1) {
                        // Remove the item from the array
                        var updatedProduct = this.product; 
                        var list = this.product.stepExtras;
                        var updatedElement = this.product.stepExtras[index]
                        updatedElement.todo.documentExtension = event.documentExtension
                        list.splice(index, 1);
                        // Insert the updated item at the original index
                        list.splice(index, 0, updatedElement);
                        updatedProduct.stepExtras = list
                        this.$emit('updateProduct:value', updatedProduct)
                    }
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: error.response.data['error'] ?? error.response.data['message'],
                })
            }
            this.isLoading = false;
        },
        hasRoleId(roleIds) {
            // Check if any of the given role IDs is present in user.roles
            return roleIds === null ? true : roleIds.some(roleId => 
              this.user.roles.some(role => role.id === roleId)
            );
        },
        async deleteElement(id){
            this.$emit('remove-element:id', id)
        },
        async handleService(object) {
            Swal.fire({
                title: titles[object.action],
                text: texts[object.action],
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                showCancelButton: true,
                input: object.action == 'rejectService' ? 'text' : null,
                inputValidator: object.action == 'rejectService' ? (value) => {
                    if (!value) {
                        return "Es necesario rellenar el motivo del rechazo";
                    }
                } : null
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.isLoading = true;
                    if(object.action == 'validateDocument'){
                        await editData(`todos/${object.todo.id}`, {status: 'DONE'}, 'El TO DO se ha guardado con exito.', 'clientTodos', 'clientTodos', this, true);
                        const index = this.product.extras.findIndex((element) => element.todo.id === object.todo.id);
                        if (index !== -1) {
                            // Remove the item from the array
                            var updatedProduct = this.product; 
                            var list = this.product.extras;
                            var updatedElement = this.product.extras[index]
                            var updatedTodo = object.todo;
                            updatedTodo.status = 'DONE';
                            updatedElement.todo = updatedTodo
                            list.splice(index, 1);
                            // Insert the updated item at the original index
                            list.splice(index, 0, updatedElement);
                            updatedProduct.extras = list
                            this.$emit('updateProduct:value', updatedProduct)
                        }
                    } else if(object.action == 'rejectDocument') {
                        await editData(`client-documents/details/${object.document.id}`, {documentExtension: null}, 'El documento se a rechazado exitosamente.', 'clientDocuments', 'clientDocuments', this, true);
                        const index = this.product.extras.findIndex((element) => element.clientDocument.id === object.document.id);
                        if (index !== -1) {
                            // Remove the item from the array
                            var updatedProduct = this.product; 
                            var list = this.product.extras;
                            var updatedElement = this.product.extras[index]
                            var updated = object.document;
                            updated.documentExtension = null;
                            updatedElement.clientDocument = updated;
                            list.splice(index, 1);
                            // Insert the updated item at the original index
                            list.splice(index, 0, updatedElement);
                            updatedProduct.extras = list
                            this.$emit('updateProduct:value', updatedProduct)
                        }
                    } else if(object.action == 'validateContract'){
                        const response = await postData('signing-v2/validate', {documentId: object.document.clientProductId}, this);
                        if (!response) {
                            return
                        };
                        const response2 = await postData('client-products/start-progress', {productId: object.document.clientProductId, clientId: this.prospectId}, this);
                        
                        if (!response2) {
                            return
                        };                      
                        Swal.fire({
                            icon: 'success',
                            title: 'Exito!',
                            text: 'Contrato validado con exito los pasos para el producto ahora estan disponibles.',
                        });
                        this.product.contractExtras.forEach((element, index) => {
                            // Update the status of each todo to 'DONE'
                            element.todo.status = 'DONE';
                        });
                        const updatedProduct = this.product;
                        updatedProduct.stepExtras = response2.stepExtras;

                        // Emit the updated product
                        this.$emit('updateProduct:value', updatedProduct);
                    } else if(object.action == 'rejectContract'){
                        const response = await postData(`client-product-contracts/reject/${object.document.clientProductId}`, {}, this);
                        if (!response) {
                            return;
                        };
                        Swal.fire({
                            icon: 'success',
                            title: 'Exito!',
                            text: 'Contrato rechazo con exito.',
                        });
                        this.product.contractExtras.forEach((element, index) => {
                            // Update the status of each todo to 'DONE'
                            element.toSignDocument.documentSigned = false;
                        });
                        
                        // Emit the updated product
                        this.$emit('updateProduct:value', this.product);
                    } else if(object.action == 'rejectService'){
                        const response = await postData(`client-products/reject`, {productId: this.product.id, rejectedReason: result.value}, this);
                        if (!response) {
                            return;
                        };
                        Swal.fire({
                            icon: 'success',
                            title: 'Exito!',
                            text: 'Producto rechazo con exito.',
                        });
                        const updatedProduct = this.product;
                        updatedProduct.status = 'REJECTED';
                        // Emit the updated product
                        this.$emit('updateProduct:value', this.product);
                    } else if(object.action == 'authorizeStep'){
                        this.onCheckedTodoRow(object.todo)
                    }
                    this.isLoading = false;
                }
            });
        },
        checkIfAllDone(tasks){
            return tasks.every(step => step.todo.status === "DONE");
        },
        checkIfBlocked(element, stepExtras) {
            // Iterate over the `blockedBy` list of the given element
            for (let blockedId of element.clientStep.stepMetaData.blockedBy) {
            // Find the step that is blocking the current element
                const blockingStep = stepExtras.find(
                    step => step.clientStep.id === blockedId
                );

            // If the blocking step is found and its status is not "DONE", return true
                if (blockingStep && blockingStep.todo.status !== "DONE") {
                    return true;
                }
            }
            // If no blocking step is found or all blocking steps have status "DONE", return false
            return false;
        },
        onCheckedTodoAppointmentRow(){
            this.todoComponentKey += 1;
        },
        async onCheckedTodoAuthorizationRow(todo){
            this.todoComponentKey += 1; 
        },
        async submitForm(formData){
            this.$emit('handle-submit-survey:value', {formData: formData, product: this.product, prospectId: this.prospectId})
        },
        async onCheckedTodoRow(todo){
            var status = todo.status == 'DONE' ? 'TODO' : 'DONE'

            await editData(`todos/${todo.id}`, {status: status}, 'El TO DO se ha guardado con exito.', 'clientTodos', 'clientTodos', this, false);
            const index = this.product.stepExtras.findIndex((element) => element.todo.id === todo.id);
            if (index !== -1) {
                // Remove the item from the array
                var updatedProduct = this.product; 
                var list = this.product.stepExtras;
                var updatedElement = this.product.stepExtras[index]
                var updatedTodo = todo;
                updatedTodo.status = status;
                updatedElement.todo = updatedTodo
                list.splice(index, 1);
                // Insert the updated item at the original index
                list.splice(index, 0, updatedElement);
                updatedProduct.stepExtras = list
                this.$emit('updateProduct:value', updatedProduct)
            }
            this.todoComponentKey += 1;        
        },
        async emitSave(formData){
            this.clientTodos = formData.clientTodos;
            await this.fetchEvents();
            this.todoComponentKey += 1;
            
            const index = this.product.stepExtras.findIndex((element) => element.todo.id === formData.todo.id);

            if (index !== -1) {
                // Remove the item from the array
                var updatedProduct = this.product; 
                var list = this.product.stepExtras;
                var updatedElement = this.product.stepExtras[index]
                updatedElement.todo = formData.todo
                list.splice(index, 1);
                // Insert the updated item at the original index
                list.splice(index, 0, updatedElement);
                updatedProduct.stepExtras = list
                this.$emit('updateProduct:value', updatedProduct)
            }
            
        },
        async fetchEvents() {
            return fetchData(`events`, 'events', 'events', this, false);
        },
        async fetchClientTodos(id){
            return fetchData(`todos/${id}`, 'clientTodos', 'clientTodos', this, false, true, 'data', false, true, 1000, 0);
        },
    },
    async created(){
        this.user = JSON.parse(localStorage.getItem('me')) || {};
        this.prospectId = this.$route.params.id;
        Promise.all([
            loadFromLocalStorage('events', 'events', this),
            loadFromLocalStorage('clientTodos', 'clientTodos', this),
        ]).then((results) =>{
            this.fetchEvents();
            this.fetchClientTodos(this.prospectId);
        })
    }
  };
  </script>
  