// src/utils/apiUtils.js
import { readFromIdb, setDataInStore } from '@/utils/loadFromLocalStorage.js';

import Swal from 'sweetalert2';
import api from '@/interceptors/axiosInterceptos'; 

export async function editData(url, body, successMessage, targetProperty, storageKey, context, showAlert, loading=true){
  try {
    if (loading) {
      context.isLoading = true;
    } 
    const token = localStorage.getItem('token');
    const headers = { 
      "authorization": `Bearer ${token}`,
    };
    const response = await api.put(`${process.env.API_URL}/${url}`, body, { headers });
    if(response.status == 200){
      
      let list = await readFromIdb(storageKey)
          
      const index = list.findIndex((element) => element.id === response.data.id);
      if (index !== -1) {
        // Remove the item from the array
        list.splice(index, 1);
        // Insert the updated item at the original index
        list.splice(index, 0, response.data);
        setDataInStore(storageKey, list)
        context[targetProperty] = list;
      }
      if (loading) {
        context.isLoading = false;
      }
      if(showAlert){

        Swal.fire({
          icon: 'success',
          title: 'Exito!',
          text: successMessage,
        });
      }
      return response.data

    }
  } catch (error) {
    if (loading) {
      context.isLoading = false;
    }
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: error.response.data['error'] ?? error.response.data['message'],
    })
    return false
  }
}